import React from "react"
import styled from 'styled-components';
import Layout from "../components/Layout"
import {graphql} from "gatsby";
import GraphQLErrorList from "../components/GraphQLErrorList";

const Container = styled.div`
    background-color: #008aff;
    height: 100vh;
    font-size: 22px;
    color: #fff;
    font-weight: 400;
    text-align: center;
    overflow: hidden;
  h1 {
    color: #fff;
    font-size: 20vw;
    font-weight: 800;
  }
  .logo {
    width: 200px;
    margin: 3rem auto;
  }
`;

export const query = graphql`
    query {
        settings: sanitySiteSettings {
            title
            id
            facebook
            instagram
            twitter
            _rawOpenGraph(resolveReferences: {maxDepth: 10})
            _rawLogo(resolveReferences: {maxDepth: 10})
        }
        footer: sanitySiteFooter {
            _rawContent(resolveReferences: {maxDepth: 10})
            _rawLinks(resolveReferences: {maxDepth: 10})
            _rawSlug(resolveReferences: {maxDepth: 10})
        }
    }
`

const NotFoundPage = ({ data, errors }) =>  {
  if (errors) {
    return (
      <GraphQLErrorList errors={errors} />
    )
  }

  return (
    <Layout settings={data.settings} footer={data.footer} hideCta>
      <Container>
        <h1>404</h1>
        <p>
          Oops, the page you're looking for does not exist.
        </p>
        <a href="/">
          <div className="logo">
            <img src={data.settings._rawLogo.asset.url} />
          </div>
        </a>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
